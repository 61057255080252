.tarjeta {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffefd5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: aparecer 2s ease-in-out;
}

h1 {
  font-size: 2.5rem;
  color: #ff69b4;
}

p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.imagen-cumple {
  width: 300px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.imagen-cumple:hover {
  transform: scale(1.05);
}

.boton-musica {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.boton-musica:hover {
  background-color: #ff1493;
}

@keyframes aparecer {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.imagen-cumple {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.boton-reproduccion {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff4081;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
  outline: none;
  position: relative; /* Necesario para el triángulo */
}

/* Crear el triángulo "play" en blanco */
.boton-reproduccion::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 15px solid white; /* Cambiado a blanco */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-30%);
}

.boton-reproduccion:hover {
  transform: scale(1.1);
  background-color: #ccc;
}

.boton-reproduccion:active {
  transform: scale(0.95);
  background-color: #bbb;
}
